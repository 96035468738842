import yup from '../../validation';
import { User } from '../User/user';

export type GuideDto = {
  readonly id: string;
  readonly name: string;
  readonly poster: string;
  readonly price: number;
  readonly tags: string;
};
export interface ImageType {
  dataURL?: string;
  file?: File;
  [key: string]: any;
}
export type GuideOptions = {
  timeZones: Readonly<string>;
  titles: Readonly<string>;
  fishingTechniques: Readonly<string>;
  typeofEngine: Readonly<string>;
  boatType: Readonly<string>;
  navEquipment: Readonly<string>;
  safetyEquipment: Readonly<string>;
  facilities: Readonly<string>;
  fishingGears: Readonly<string>;
  additionalCrew: Readonly<string>;
  fishSpecies: Readonly<string>;
  isNecessaryGearIncluded: Readonly<boolean>;
  isFishingLicenseIncluded: Readonly<boolean>;
  alcoholAllowed: Readonly<boolean>;
  foodAndBeverages: Readonly<string>;
  catchPolicy: Readonly<string>;
  country: Readonly<string>;
  streetAddress: Readonly<string>;
  postalCode: Readonly<string>;
  pickupPolicy: Readonly<string>;
  cancellationPolicy: Readonly<number>;
  freeCancelation: Readonly<number>;
  priceCurrency: Readonly<string>;
};

export type GuideDetails = {
  id?: string;
  guideProfile: GuideProfile;
  businessProfile: BusinessProfile;
  presentYourBoats?: PresentYourBoat[];
  presentYourTrips?: TripDetails[];
};

export type GuideProfile = {
  hotoFile?: File;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  profilePhoto?: MediaFileDto;
  title: MasterData;
  languagesSpoken: string[];
  currency: string;
  timeZone: string;
  status?: string;
  createdAt?: string;
  experience?: number | null;
  website?: string | null;
};

export type ChangePassword = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export type BusinessProfile = {
  licensesCertificates: boolean;
  website?: string | null;
  businessName: string;
  businessDescription: string;
  businessEmail: string;
  businessPhone: string;
  certificates: boolean;
  country: string;
  city: string;
  streetAddress: string;
  postalCode: string;
  businessMedia?: MediaFileDto;
  createdAt?: string | null;
  enableBusinessMediaUpload?: boolean;
};

export type MasterData = {
  id?: string;
  label: string;
  value: string;
};

export type PresentYourBoat = {
  boatType: MasterData;
  boatLength: number;
  guestCapacity: number;
  extraMotor?: boolean;
  boatManufacturer?: string;
  wheelchairAccessible?: boolean;
  typeofEngine?: MasterData;
  engineManufacturer?: string;
  numberofEngines?: number;
  horsePowerPerEngine?: number | null;
  maxCruisingSpeed?: number;
  navEquipments?: MasterData[];
  safetyEquipments?: MasterData[];
  facilities?: MasterData[];
  fishingGears?: MasterData[];
  additionalCrew?: MasterData[];
  id?: string;
  status?: ModelStatus;
  boatMedia?: MediaFileDto;
  guide?: any;
};

export type TripDetails = {
  id?: string;
  name: string;
  description: string;
  type: string;
  duration: string;
  departure: string;
  isSeasonalTrip: boolean;
  season?: Season | null;
  maxAmountofPeople?: number | null;
  isSharedTrip: boolean;
  pricePerPerson?: Price;
  minAmountofPeople?: number | null;
  priceifNotShared?: Price;
  targetedSpecies: MasterData[];
  fishingTechniques: MasterData[];
  fishingTypes: MasterData[];
  isNecessaryGearIncluded: boolean;
  isFishingLicenseIncluded: boolean;
  alcoholAllowed: boolean;
  foodAndBeverages: MasterData[];
  catchPolicy: MasterData[];
  country: string;
  streetAddress: string;
  postalCode: string;
  pickupPolicy: MasterData;
  cancellationPolicy: MasterData | any;
  freeCancellation?: number;
  city: string;
  minimumAge: number;
  status: ModelStatus;
  long: number;
  lat: number;
  tripMedia?: MediaFileDto[];
  rating?: number;
  guide_boat?: PresentYourBoat | any;
  guide?: any;
  operateDays?: string[];
  multiBoatTrip?: boolean;
};

export enum ModelStatus {
  Edited,
  New,
  Deleted,
  UnEdited,
}

export type Price = {
  value?: number;
  currency?: string;
};

export type Season = {
  from?: Date;
  to?: Date;
};

export const guideProfileSchema = yup.object().shape({
  hotoFile: yup.mixed(),
  title: yup.mixed<MasterData>().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  languagesSpoken: yup.array().required(),
  phoneNumber: yup.string().required(),
  email: yup.string().email().required(),
  timeZone: yup.string().required(),
  currency: yup.string().required(),
  profilePhoto: yup.mixed(),
  status: yup.string().optional(),
  createdAt: yup.string().optional(),
  website: yup.string().url().optional().nullable(),
  experience: yup
    .number()
    .nullable()
    .optional()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
});

export const changePasswordSchema = yup.object().shape({
  currentPassword: yup.string().required(),
  newPassword: yup
    .string()
    .required()
    .min(8, 'validators.min_password_length')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      'validators.password_match_condition'
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), undefined], 'validators.password_match')
    .required(),
});

export const businessProfileSchema = yup.object().shape({
  businessName: yup.string().required(),
  businessDescription: yup.string().required(),
  businessEmail: yup.string().email().required(),
  businessPhone: yup.string().required(),
  certificates: yup.boolean().required(),
  licensesCertificates: yup.boolean().required(),
  website: yup.string().url().optional().nullable(),
  country: yup.string().required(),
  city: yup.string().required(),
  streetAddress: yup.string().required(),
  postalCode: yup.string().required(),
  businessMedia: yup.mixed(),
  createdAt: yup.string().optional(),
  enableBusinessMediaUpload: yup.boolean().optional(),
});

export const presentYourBoatSchema = yup.object().shape({
  boatType: yup.mixed<MasterData>().required(),
  boatLength: yup.number().required(),
  guestCapacity: yup.number().required(),
  extraMotor: yup.boolean().optional(),
  boatManufacturer: yup.string().optional(),
  wheelchairAccessible: yup.boolean().optional(),
  typeofEngine: yup.mixed<MasterData>().optional(),
  engineManufacturer: yup.string().optional(),
  numberofEngines: yup
    .number()
    .optional()
    .transform((v) => (!v ? undefined : v)),
  horsePowerPerEngine: yup
    .number()
    .nullable()
    .optional()
    .transform((v) => (!v ? null : v)),
  maxCruisingSpeed: yup
    .number()
    .optional()
    .transform((v) => (!v ? undefined : v)),
  navEquipments: yup.array().optional(),
  safetyEquipments: yup.array().optional(),
  facilities: yup.array().optional(),
  fishingGears: yup.array().optional(),
  additionalCrew: yup.array().optional(),
  id: yup.string(),
  guide: yup.mixed(),
  status: yup.mixed<ModelStatus>(),
  boatMedia: yup.mixed<MediaFileDto>().optional(),
});

export const tripDetailsSchema = yup.object().shape({
  id: yup.string(),
  name: yup.string().required(),
  description: yup.string().required(),
  type: yup.string().required(),
  duration: yup.string().required(),
  departure: yup.string().required(),
  isSeasonalTrip: yup.boolean().required(),
  isSharedTrip: yup.boolean().required(),
  targetedSpecies: yup.array().required(),
  fishingTechniques: yup.array().required(),
  fishingTypes: yup.array().required(),
  isNecessaryGearIncluded: yup.boolean().required(),
  isFishingLicenseIncluded: yup.boolean().required(),
  alcoholAllowed: yup.boolean().required(),
  foodAndBeverages: yup.array().required(),
  catchPolicy: yup.array().required(),
  country: yup.string().required(),
  streetAddress: yup.string().required(),
  postalCode: yup.string().required(),
  pickupPolicy: yup.mixed<MasterData>().required(),
  cancellationPolicy: yup.mixed<MasterData>().required(),
  freeCancellation: yup.number(),
  city: yup.string().required(),
  minimumAge: yup.number().required(),
  status: yup.mixed<ModelStatus>().required(),
  long: yup.number().required(),
  lat: yup.number().required(),
  tripMedia: yup.array(),
  pricePerPerson: yup.mixed(),

  minAmountofPeople: yup
    .number()
    .integer()
    .nullable()
    .optional()
    .when('isSharedTrip', (isSharedTrip, schema) => {
      if (isSharedTrip && isSharedTrip.toString() === 'true') return schema.required();
      return schema;
    }),

  priceifNotShared: yup.mixed(),
  maxAmountofPeople: yup.number().integer().nullable().optional(),
  season: yup.mixed(),
  guide_boat: yup.mixed().nullable().optional(),
  guide: yup.mixed(),
  operateDays: yup.array(),
  multiBoatTrip: yup.boolean(),
});

export const GuideDetailsSchema = yup.object().shape({
  id: yup.string(),
  guideProfile: guideProfileSchema,
  businessProfile: businessProfileSchema,
  presentYourBoats: yup.array().of(presentYourBoatSchema),
  presentYourTrips: yup.array().of(tripDetailsSchema),
});

export interface Conversation {
  id: number;
  buyer: {
    id: number;
    name: string;
  };
  seller: {
    id: number;
    name: string;
  };
  trip: {
    id: number;
    name: string;
  };

  buyer_status: string;
  seller_status: string;
  last_message: Message;
  guide: {
    id: number;
    name: string;
    profilePhoto?: string;
  };
}

export interface Message {
  id: number;
  sent_at: string;
  message: string;
  sender: User;
}
