import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSubscribeNewsletter } from '../../services/EmailPreference/emailPreferenceService';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NotificationManager } from 'react-notifications';
import * as countryZones from './country-timezone.json';

type NewsLetterSubscriber = { email: string };

const newsLetterSubscriberSchema = yup
  .object()
  .shape({ email: yup.string().email().required() })
  .required();

function NewsLetter() {
  const { t } = useTranslation();
  const { mutateAsync: subscribeNewsletter } = useSubscribeNewsletter();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<NewsLetterSubscriber>({
    mode: 'all',
    defaultValues: {},
    resolver: yupResolver<NewsLetterSubscriber>(newsLetterSubscriberSchema),
  });

  const onSubmit = async (data: any) => {
    console.log(data);
    try {
      const { countries, zones } = countryZones as any;
      const timeZoneCityToCountry = {} as any;
      Object.keys(zones).forEach((z) => {
        const cityArr = z.split('/');
        const city = cityArr[cityArr.length - 1];
        timeZoneCityToCountry[city] = countries[zones[z].countries[0]].name;
      });
      let userCountry;
      let userCity;
      if (Intl) {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const tzArr = userTimeZone.split('/');
        userCity = tzArr[tzArr.length - 1];
        userCountry = timeZoneCityToCountry[userCity];
      }
      //  console.log('Country:', userCountry);
      await subscribeNewsletter({
        ...data,
        send_newsletters: true,
        country: userCountry || userCity,
      });
      NotificationManager.success(t('Thanks for subscribing our Newsletter.'));
    } catch (e) {
      console.log(e);
      NotificationManager.error(t('e-mail address already registered!'));
    }
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h5 className="font-weight-bold  mt-3 mb-4">{t('Subscribe.btn')}</h5>
      <p>Get great offers and the latest updates by signing up to our newsletter.</p>

      <div className="d-table-cell w-100">
        <input
          {...register('email')}
          className="form-control mr-1"
          placeholder={t('Your E-mail')}
        />
        {errors.email && errors.email.message && (
          <span className="text-danger text-start">
            {t(errors.email.message).replace('{min}', '8')}
          </span>
        )}
      </div>
      <div className="d-table-cell align-middle">
        <button className="btn btn-gold" type="submit" style={{ borderRadius: 0 }}>
          Submit
        </button>
      </div>
    </form>
  );
}

export default NewsLetter;
