import parse from 'html-react-parser';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ButtonSpinner } from '../../components/Spinner/ButtonSpinner';
import { useGetPrivacyPage } from '../../services/User/userService';

const Privacy = () => {
  const { t, i18n } = useTranslation();
  const { data, isLoading } = useGetPrivacyPage();
const descriptionContent = () => {
  switch (i18n.language) {
    case 'sv':
      return (data && data.svdescription) || '';
    case 'de':
      return (data && data.dedescription) || '';
    case 'fr':
      return (data && data.frdescription) || '';
    default:
      return (data && data.description) || '';
  }
}

const operatorContent = () => {
  switch (i18n.language) {
    case 'sv':
      return (data && data.svoperator) || '';
    case 'de':
      return (data && data.deoperator) || '';
    case 'fr':
      return (data && data.froperator) || '';
    default:
      return (data && data.operator) || '';
  }
}

const guestUserContent = () => {
  switch (i18n.language) {
    case 'sv':
      return (data && data.svguestUser) || '';
    case 'de':
      return (data && data.deguestUser) || '';
    case 'fr':
      return (data && data.frguestUser) || '';
    default:
      return (data && data.guestUser) || '';
  }
}

const visitorContent = () => {
  switch (i18n.language) {
    case 'sv':
      return (data && data.svvisitor) || '';
    case 'de':
      return (data && data.devistor) || '';
    case 'fr':
      return (data && data.frvisitor) || '';
    default:
      return (data && data.visitor) || '';
  }
}
  return (
    <div className="info-page">
      <div
        className="container-fluid display_operator_image"
        style={{ backgroundImage: 'url("/img/image.jpg")' }}
      >
        <div className="row">
          <div className="display_operator-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center whitetext">
            <h6>{t('privacyPage.subheading')}</h6>
            <h2 className="display_operator_name">{t('privacyPage.heading')}</h2>
          </div>
        </div>
      </div>
      <div
        className="container"
        style={{ backgroundColor: '#FFF', marginTop: '-90px', maxWidth: '960px' }}
      >
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-5">
            <div className="display_operator_info mb-3">
              {isLoading && <ButtonSpinner />} {data && parse(descriptionContent())}
            </div>
          </div>
        </div>

        <div>
          <div className="row">
            <div className="col-md-12">
              <div className="tab">
                <Tabs id="controlled-tab-example" defaultActiveKey="visitors" className="mb-3">
                  <Tab eventKey="visitors" title={t('visitors')}>
                    {isLoading && <ButtonSpinner />} {data && parse(visitorContent())}
                  </Tab>
                  <Tab eventKey="guest" title={t('guestUser')}>
                    {isLoading && <ButtonSpinner />} {data && parse(guestUserContent())}
                  </Tab>
                  <Tab eventKey="operator" title={t('operator')}>
                    {isLoading && <ButtonSpinner />} {data && parse(operatorContent())}
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
